import { createApi } from '@reduxjs/toolkit/query/react';
import { authQuery } from '../config/base-query';
import { cookieService } from '@lib/cookie.service';
import { QUERIES } from '@constants/query.constants';
import {
  IErrorResponse,
  ICreator,
  ICreatorsResponse,
  IStoreDetailsResponse,
} from 'app/(app)/creator.typings';
import { ICreatorSocialProfile, ISocialProfileResponse } from 'app/(app)/creator/creator.typings';

export const creatorsApi = createApi({
  reducerPath: 'creators-api',
  baseQuery: authQuery,
  tagTypes: [QUERIES.badges, QUERIES.creators, QUERIES.store],
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getCreators: builder.query<ICreator[], void>({
      query() {
        const userId = cookieService.getUserId();

        return {
          url: userId ? `/users/companies-by-user/${userId}` : '',
        };
      },
      keepUnusedDataFor: 3600,
      transformResponse(data: ICreatorsResponse[]) {
        return data.map((item) => {
          return {
            name: item.displayName || '',
            id: item.companyId,
            avatar: item.avatar || '',
            banner: item.logo || '',
            notifications: item.unreadMessages + item.unmarkedConversations,
            hasArchivedMessages: item.unreadMessages > 0,
            badges: item.badgesFromAuthor,
            fans: item.totalFans,
            membershipSettings: item.membershipSettings,
            monetizationSettings: item.monetizationSettings,
          };
        });
      },
      providesTags: [QUERIES.creators],
    }),
    getCreatorSocialProfile: builder.query<ICreatorSocialProfile | null, string>({
      query(creatorId) {
        return {
          url: `/social-profile/${creatorId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 900,
      transformResponse(response: ISocialProfileResponse | null) {
        return response
          ? {
              welcome: response.socialProfile.title,
              bio: response.socialProfile.biography,
              fansAmount: response.totalFans,
              badgeAmount: response.totalClaims,
              communityBadge: response?.communityBadge,
              socialLinks: response.socialProfile.socialLinks
                ? [
                    {
                      key: 'YouTube',
                      href: response.socialProfile.socialLinks.youtube
                        ? `https://youtube.com/${response.socialProfile.socialLinks.youtube}`
                        : '',
                    },
                    {
                      key: 'Instagram',
                      href: response.socialProfile.socialLinks.instagram
                        ? `https://instagram.com/${response.socialProfile.socialLinks.instagram}`
                        : '',
                    },
                    {
                      key: 'X',
                      href: response.socialProfile.socialLinks.twitter
                        ? `https://twitter.com/${response.socialProfile.socialLinks.twitter}`
                        : '',
                    },
                    {
                      key: 'Facebook',
                      href: response.socialProfile.socialLinks.facebook
                        ? `https://www.facebook.com/${response.socialProfile.socialLinks.facebook}`
                        : '',
                    },
                  ].filter((item) => !!item.href)
                : [],
              creatorInfo: response.creatorInfo,
            }
          : {
              welcome: '',
              bio: '',
              fansAmount: 0,
              badgeAmount: 0,
              socialLinks: [],
              communityBadge: [],
              creatorInfo: null,
            };
      },
    }),

    getCreatorStoreDetails: builder.query<IStoreDetailsResponse, string>({
      query: (creatorId) => {
        return {
          url: `/store-details/${creatorId}`,
        };
      },
      transformResponse: (response: IStoreDetailsResponse | IErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      providesTags: [QUERIES.creators],
    }),
  }),
});

export const {
  useGetCreatorsQuery,
  useLazyGetCreatorsQuery,
  useGetCreatorSocialProfileQuery,
  useGetCreatorStoreDetailsQuery,
} = creatorsApi;
