import { CSSProperties, useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { OnboardIcon } from '@ui/components/atoms/icon/icon-assets/onboard';
import { useMobile } from '@ui/hooks';
import './onboarding.css';
import { OnboardingState } from '@onboarding/services';

interface Props {
  state: OnboardingState;
  className?: string;
  style?: CSSProperties;
  progress: number;
  currentChecklistEvent?: string | undefined;
  triggerCloseAnim?: boolean;
  creatorId: string | undefined;
  shouldChecklistAnimate?: boolean;
  onAnimationAction: (value?: boolean) => void;
  shouldShowOnboardingTrigger?: boolean;
}

export const OnboardingModalCTA = ({
  className,
  style,
  progress,
  triggerCloseAnim,
  creatorId,
  shouldChecklistAnimate,
  onAnimationAction,
  state,
  currentChecklistEvent,
  shouldShowOnboardingTrigger = true,
}: Props) => {
  const [showText, setShowText] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const isMobile = useMobile();
  const prevCreatorId = useRef(creatorId);

  useEffect(() => {
    if (!progress) return;

    if (progress > currentProgress) {
      // Check if the creator ID hasn't changed before triggering animation
      if (prevCreatorId.current === creatorId) {
        setIsAnimating(true);
      }
    }

    const timer = setTimeout(() => {
      setIsAnimating(false);
      onAnimationAction();
    }, 5000);

    setCurrentProgress(progress);
    return () => clearTimeout(timer);
  }, [progress, creatorId, onAnimationAction]);

  useEffect(() => {
    if (showText) {
      const timer = setTimeout(() => setShowText(false), 10000);
      return () => clearTimeout(timer);
    }
  }, [showText]);

  useEffect(() => {
    const currentChecklist = state?.progress?.tasklist?.find(
      (item) => item?.type === currentChecklistEvent,
    );
    if (
      state?.progress?.progress?.has_new_task &&
      currentChecklist &&
      !currentChecklist?.completed
    ) {
      setIsAnimating(true);
      onAnimationAction(true);
    }
  }, [state?.progress?.progress?.has_new_task, currentChecklistEvent]);

  // Calculate dimensions for the progress ring
  const radius = isMobile ? 32 : 40;
  const strokeWidth = isMobile ? 2 : 4;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progressOffset = ((100 - currentProgress) / 100) * circumference;

  // Early return if not visible
  if (!shouldShowOnboardingTrigger) return null;

  return (
    <div
      className={twMerge(
        'fixed right-[-12px] md:right-[-5px] bottom-0 pointer-events-none z-50',
        className,
      )}
      style={style}>
      <motion.div
        className="flex items-center gap-2 p-4 pointer-events-auto"
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', stiffness: 100, damping: 15, duration: 1 }}>
        <AnimatePresence>
          {isHovered && (
            <motion.div
              className="bg-primaryLight px-4 py-2 text-sm rounded-xl font-semibold pointer-events-auto"
              initial={{ opacity: 0, x: 50 }}
              animate={{
                opacity: showText || isHovered ? 1 : 0,
                x: showText || isHovered ? 0 : 50,
                scale: showText || isHovered ? 1 : 0.95,
              }}
              exit={{ opacity: 0, x: 50, scale: 0.95 }}
              transition={{ duration: 0.5 }}>
              Onboarding checklist ({Math.round(currentProgress)}% complete)
            </motion.div>
          )}
        </AnimatePresence>

        <div
          className="relative pointer-events-auto outline-none focus:outline-none"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ WebkitTapHighlightColor: 'transparent' }}>
          <div className="sticky bottom-0 right-0">
            <div className="progress-ring-container w-20 h-20">
              <svg
                className="progress-ring"
                width={radius * 2}
                height={radius * 2}
                style={{ outline: 'none' }}>
                {/* Background circle */}
                <circle
                  className="progress-ring-circle-bg"
                  stroke="#FBD0B6"
                  fill="transparent"
                  strokeWidth={strokeWidth}
                  r={normalizedRadius}
                  cx={radius}
                  cy={radius}
                />
                {/* Progress circle */}
                <circle
                  className={`progress-ring-circle ${
                    isAnimating && shouldChecklistAnimate ? 'glowing' : ''
                  }`}
                  stroke="#FF6F24"
                  fill="transparent"
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  r={normalizedRadius}
                  cx={radius}
                  cy={radius}
                  style={{
                    strokeDasharray: `${circumference} ${circumference}`,
                    strokeDashoffset: progressOffset,
                  }}
                />
              </svg>
              <motion.div
                className="icon-container"
                initial={{ scale: 0, rotate: 0 }}
                animate={{
                  scale: 1.2,
                  rotate:
                    isHovered || (isAnimating && shouldChecklistAnimate) || triggerCloseAnim
                      ? 360
                      : 0,
                }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                  damping: 30,
                  duration: 1,
                }}
                onAnimationComplete={() => setShowText(true)}>
                <OnboardIcon className="w-12 h-12 md:w-16 md:h-16" />
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
