import React from 'react';
import animationData from './congrat-confetti.json';
import dynamic from 'next/dynamic';
import { Button } from '@ui/components';

const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

interface CongratsProps {
  userType: 'fan' | 'creator';
  onClose: () => void;
  onHideModalCta?: () => void;
}

const Congrats = ({ userType, onClose, onHideModalCta }: CongratsProps) => {
  const onButtonClick = () => {
    if (userType === 'fan') {
      onClose();
      onHideModalCta!();
    } else {
      onClose();
    }
  };
  return (
    <div>
      <div className="absolute top-0">
        <Lottie animationData={animationData} loop={true} className="w-full h-full" />
      </div>
      <div>
        {/* Content Container */}
        <div className="relative z-10 flex flex-col items-center justify-center w-full h-full p-8">
          <p className="text-sm space-x-2 text-center text-primary mb-4 drop-shadow-lg flex items-center">
            {/* <CircleCheckBig className="w-4 h-4" /> */}
            <span>Onboarding task completed</span>
            <div className="absolute">
              <Lottie animationData={animationData} loop={true} className="w-full h-full" />
            </div>
          </p>
          <h2 className="text-4xl md:text-5xl font-bold text-center text-black mb-4 drop-shadow-lg">
            Congratulations! 🎉
          </h2>
          <p className="text-[14px] max-w-[500px] space-x-2 text-center text-black ">
            <span className="font-bold">
              Congratulations for completing the onboarding checklist.
            </span>{' '}
            When new features are activated this list will reappear with how the new feature works.
          </p>

          <Button
            type="primary"
            onClick={onButtonClick}
            className="mt-8 px-8 py-3 rounded-full transition-colors font-semibold shadow-lg backdrop-blur-sm">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Congrats;
