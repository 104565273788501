import React, { useMemo, useState } from 'react';
import { INoteBook, INotebookStateCreator } from '@cms/services';
import { Text, Note, Icon } from '@ui/components';
import { ContentItemMenu } from './content-item-menu';
import { useRouter, useSearchParams } from 'next/navigation';
import dayjs from 'dayjs';
import { ContentItemDeleteModal } from './content-delete-modal';
import { PinIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

interface IContentItemProps {
  notebook: INoteBook;
  state?: INotebookStateCreator;
  userType?: string;
}

export const NotebookItem: React.FC<IContentItemProps> = ({ notebook, state, userType }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [notebookToDelete, setNotebookToDelete] = useState<string | null>(null);

  const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setNotebookToDelete(notebook?.id || null);
    setDeleteModalOpen(true);
  };

  const router = useRouter();
  const params = useSearchParams();
  const creator_id = params.get('id');
  const route =
    userType === 'creator'
      ? `/dashboard/contents/editor?id=${notebook?.id}`
      : `/creator/contents/content?notebook_id=${notebook?.id}&id=${creator_id}`;

  const handlePublish = (e: React.MouseEvent<HTMLDivElement>) => {
    setDropdownOpen(false);
    e.stopPropagation();
    e.preventDefault();
    router.push(route);
  };

  const dynamicDate = notebook?.date_of_publish ? notebook?.date_of_publish : notebook?.created_at;

  const shouldShowPublishDate = useMemo(() => {
    if (!notebook?.miscellaneous) return true;
    return notebook?.miscellaneous?.showPublishDate ?? true;
  }, [notebook?.miscellaneous]);

  const handlePin = async (e: React.MouseEvent<HTMLDivElement>) => {
    setDropdownOpen(false);
    e.stopPropagation();
    e.preventDefault();
    await state?.handleTogglePin(notebook);
  };

  return (
    <div
      className={twMerge(
        'relative w-full h-[300px] rounded-md pb-4 cursor-pointer border border-gray-300',
        notebook?.pinned_at && 'border-primaryActive',
      )}
      key={notebook?.id}>
      <div
        className={`relative bg-primaryLight/10 w-full rounded-t-md'
          }`}
        onClick={(e) => handlePublish(e)}>
        {notebook?.pinned_at && (
          <div className="flex items-center absolute top-[-16px] right-4 bg-primaryLight h-8 w-8 rounded-full justify-center z-50">
            <PinIcon className="text-primaryActive font-thin h-4 w-4 fill-white" />
          </div>
        )}

        <div className="relative rounded-t-md overflow-hidden h-[180px] flex justify-center items-center">
          {notebook?.banner_url && (
            <img
              src={notebook?.banner_url}
              alt="banner"
              className="absolute inset-0 object-cover w-full h-[170px] blur-md"
            />
          )}
          {notebook?.banner_url && (
            <img
              src={notebook?.banner_url}
              alt="banner"
              className="relative w-full h-[180px] object-contain z-40"
            />
          )}
        </div>

        {userType === 'fan' && !notebook?.has_access && (
          <div className="absolute bg-black/50 top-0 right-0 w-full h-full rounded-t-md flex items-center justify-center flex-col space-y-1 z-40">
            <div className="mt-8 flex items-center bg-white border max-w-[200px] rounded-2xl py-2 text-sm space-x-1 px-6 font-semibold">
              <Icon type="lock" stroke="#000" />
              <span>Unlock content</span>
            </div>
            <div className="text-xs text-white text-center">Available by subscription</div>
          </div>
        )}
      </div>

      {userType === 'creator' && (
        <ContentItemDeleteModal
          open={isDeleteModalOpen}
          setOpen={setDeleteModalOpen}
          notebookId={notebookToDelete}
          state={state!}
        />
      )}

      <div
        className=" flex flex-col justify-center pl-4 w-full rounded-bl-md rounded-br-md"
        onClick={(e) => handlePublish(e)}>
        <div className="flex items-center justify-between pt-4">
          <Text
            type="m"
            className="text-base font-semibold text-gray-800 w-full max-w-[90%] lg:max-w-[80%] xl:max-w-[75%] pr-2 overflow-hidden text-ellipsis whitespace-nowrap">
            {notebook?.name}
          </Text>

          {userType === 'creator' && (
            <ContentItemMenu
              isOpen={dropdownOpen}
              onOpenChange={setDropdownOpen}
              onPublish={(e) => handlePublish(e)}
              onDelete={(e) => handleDelete(e)}
              onPin={{ handlePin, pinned: !!notebook?.pinned_at }}
            />
          )}
        </div>

        <Text
          type="m"
          className="text-note text-xs line-clamp-2 w-full pr-2 my-1 lg:max-w-[275px] max-w-[200px]">
          {notebook?.description}
        </Text>

        {shouldShowPublishDate && (
          <div className="flex items-center">
            <Note type="xs" className="text-xs font-light">
              {notebook?.date_of_publish
                ? 'Published'
                : userType === 'creator'
                ? 'Draft, created'
                : 'Created'}{' '}
              {dayjs(dynamicDate).format('DD MMM')}
            </Note>
          </div>
        )}
      </div>
    </div>
  );
};
