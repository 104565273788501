'use client';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { INoteBook } from '@cms/services';
import { Banner } from '@ui/components';

type NotebookHero = {
  singleNotebook: INoteBook;
};

const NotebookHero = ({ singleNotebook }: NotebookHero) => {
  const shouldShowPublishDate = useMemo(() => {
    if (!singleNotebook?.miscellaneous) return true;
    return singleNotebook?.miscellaneous?.showPublishDate ?? true;
  }, [singleNotebook?.miscellaneous]);
  return (
    <div>
      {singleNotebook?.banner_url && (
        <Banner
          type="content"
          image={singleNotebook?.banner_url}
          className="rounded w-full mb-[30px] object-center h-auto object-contain max-h-[300px]"
        />
      )}

      <div className="mb-[30px] space-y-2">
        {shouldShowPublishDate && <p>{dayjs(singleNotebook?.created_at).format('DD MMM')}</p>}

        {singleNotebook?.name && (
          <h1 className="text-2xl md:text-4xl font-bold">{singleNotebook?.name}</h1>
        )}

        {singleNotebook?.description && <p>{singleNotebook?.description}</p>}
      </div>
    </div>
  );
};

export default NotebookHero;
