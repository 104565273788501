export const QUERIES = {
  user: 'user',
  badges: 'badges',
  creators: 'creators',
  globalLeaderboard: 'global-leaderboard',
  leaderboards: 'leaderboards',
  leaderboardFans: 'leaderboard-fans',
  conversations: 'conversations',
  communityConversations: 'community-conversations',
  singleConversation: 'singleConversation',
  messages: 'messages',
  messagesV1: 'messagesV1',
  singleMessage: 'singleMessage',
  userSuggestions: 'user-suggestions',
  engagementScore: 'engagement-score',
  store: 'store',
};
